export function Education() {
  return (
    <div>
      <h1>Education Page</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet
        eget mi ut efficitur. Pellentesque vehicula dui ante, ac aliquam ipsum
        volutpat imperdiet. Suspendisse sollicitudin accumsan tellus. Curabitur
        semper, mi nec molestie tincidunt, lectus diam vulputate eros, a mollis
        felis risus in mi. Nam placerat ex ac nulla posuere suscipit. Donec sit
        amet tincidunt turpis. Mauris cursus efficitur erat, a mattis est congue
        vitae. Curabitur vehicula vulputate vulputate. Cras in ante nulla.
        Aliquam lobortis ex et ipsum cursus, bibendum viverra velit facilisis.
        Cras urna purus, tristique vitae tempus sit amet, porttitor sed orci.
        Aenean eget dolor sit amet ante imperdiet gravida eget ut augue. Duis
        dapibus eget sem rhoncus luctus. In tincidunt ante nisl, id mollis dui
        scelerisque ac. Vivamus consequat enim vel efficitur mattis. Curabitur
        vulputate et augue interdum varius.
      </p>
      <p>
        Ut quis risus commodo, cursus lorem eget, tempus nibh. Aliquam consequat
        viverra dapibus. Aenean aliquam pellentesque sem id lacinia. Nam non
        dolor volutpat, euismod mauris ut, gravida ante. Suspendisse eleifend,
        velit id cursus pretium, risus metus tempor elit, vel suscipit leo quam
        eget nisl. Cras sed ante erat. Donec eget consectetur risus. Sed
        elementum ante tortor, at lobortis orci eleifend non. Interdum et
        malesuada fames ac ante ipsum primis in faucibus. Mauris et urna
        euismod, aliquam nulla a, placerat lorem. Maecenas magna turpis,
        vestibulum eu neque a, imperdiet vestibulum orci. Pellentesque iaculis
        ac nibh non volutpat.
      </p>
      <p>
        Phasellus aliquet leo in erat semper, et auctor metus auctor. Donec
        massa quam, efficitur volutpat aliquet in, lacinia sed erat. Mauris eget
        tellus ac leo molestie eleifend vel sit amet urna. Morbi at aliquet mi.
        Aliquam erat volutpat. Nunc accumsan non diam vel luctus. Duis porta
        lorem a nunc porta, sed lacinia velit placerat. Curabitur non iaculis
        erat. Nam sed tincidunt, id iaculis lorem commodo. Phasellus tristique
        id massa vel placerat. Sed ut libero augue. Nullam fringilla magna ut
        tortor maximus bibendum. Donec eu luctus dolor. Morbi consectetur non
        enim id efficitur. Suspendisse sit amet ex vel felis mattis laoreet
        vitae ac est. Vivamus molestie ex et dolor tempus, at aliquet libero
        tincidunt. In lectus odio, porta eget tristique eu, pharetra scelerisque
        felis. Phasellus elementum risus non posuere consequat. Donec id dolor
        laoreet, tincidunt turpis a, interdum nulla. Nunc ut mi at sem ultricies
        pulvinar eu nec velit. Nulla sed felis ipsum. Sed tempor, mauris in
        feugiat porta, magna sem rhoncus metus, vitae posuere nunc lacus quis
        magna. Aenean quis commodo nisl. Vivamus viverra urna quis mollis
        aliquet. Suspendisse dapibus, lectus sit amet convallis malesuada, est
        ex eleifend justo, at pellentesque tellus urna eget diam. Aenean
        suscipit mauris non mauris euismod, at tempus lacus sagittis.
        Suspendisse iaculis efficitur eleifend. Cras eu pellentesque velit.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet
        eget mi ut efficitur. Pellentesque vehicula dui ante, ac aliquam ipsum
        volutpat imperdiet. Suspendisse sollicitudin accumsan tellus. Curabitur
        semper, mi nec molestie tincidunt, lectus diam vulputate eros, a mollis
        felis risus in mi. Nam placerat ex ac nulla posuere suscipit. Donec sit
        amet tincidunt turpis. Mauris cursus efficitur erat, a mattis est congue
        vitae. Curabitur vehicula vulputate vulputate. Cras in ante nulla.
        Aliquam lobortis ex et ipsum cursus, bibendum viverra velit facilisis.
        Cras urna purus, tristique vitae tempus sit amet, porttitor sed orci.
        Aenean eget dolor sit amet ante imperdiet gravida eget ut augue. Duis
        dapibus eget sem rhoncus luctus. In tincidunt ante nisl, id mollis dui
        scelerisque ac. Vivamus consequat enim vel efficitur mattis. Curabitur
        vulputate et augue interdum varius.
      </p>
      <p>
        Ut quis risus commodo, cursus lorem eget, tempus nibh. Aliquam consequat
        viverra dapibus. Aenean aliquam pellentesque sem id lacinia. Nam non
        dolor volutpat, euismod mauris ut, gravida ante. Suspendisse eleifend,
        velit id cursus pretium, risus metus tempor elit, vel suscipit leo quam
        eget nisl. Cras sed ante erat. Donec eget consectetur risus. Sed
        elementum ante tortor, at lobortis orci eleifend non. Interdum et
        malesuada fames ac ante ipsum primis in faucibus. Mauris et urna
        euismod, aliquam nulla a, placerat lorem. Maecenas magna turpis,
        vestibulum eu neque a, imperdiet vestibulum orci. Pellentesque iaculis
        ac nibh non volutpat.
      </p>
      <p>
        Phasellus aliquet leo in erat semper, et auctor metus auctor. Donec
        massa quam, efficitur volutpat aliquet in, lacinia sed erat. Mauris eget
        tellus ac leo molestie eleifend vel sit amet urna. Morbi at aliquet mi.
        Aliquam erat volutpat. Nunc accumsan non diam vel luctus. Duis porta
        lorem a nunc porta, sed lacinia velit placerat. Curabitur non iaculis
        erat. Nam sed tincidunt, id iaculis lorem commodo. Phasellus tristique
        id massa vel placerat. Sed ut libero augue. Nullam fringilla magna ut
        tortor maximus bibendum. Donec eu luctus dolor. Morbi consectetur non
        enim id efficitur. Suspendisse sit amet ex vel felis mattis laoreet
        vitae ac est. Vivamus molestie ex et dolor tempus, at aliquet libero
        tincidunt. In lectus odio, porta eget tristique eu, pharetra scelerisque
        felis. Phasellus elementum risus non posuere consequat. Donec id dolor
        laoreet, tincidunt turpis a, interdum nulla. Nunc ut mi at sem ultricies
        pulvinar eu nec velit. Nulla sed felis ipsum. Sed tempor, mauris in
        feugiat porta, magna sem rhoncus metus, vitae posuere nunc lacus quis
        magna. Aenean quis commodo nisl. Vivamus viverra urna quis mollis
        aliquet. Suspendisse dapibus, lectus sit amet convallis malesuada, est
        ex eleifend justo, at pellentesque tellus urna eget diam. Aenean
        suscipit mauris non mauris euismod, at tempus lacus sagittis.
        Suspendisse iaculis efficitur eleifend. Cras eu pellentesque velit.
      </p>
    </div>
  );
}
