import styled from "styled-components";

export const VideoContainer = styled.div`
  position: relative;

  video {
    width: 100%;
    display: block;
  }
`;

export const PromptContainer = styled.div`
  margin-top: 16px;
`;
