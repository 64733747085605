import styled, { css } from "styled-components";
import { tablet } from "styles/breakpoints";

export const Headings = styled.div`
  padding-bottom: 4px;

  @media (min-width: ${tablet}px) {
    padding-bottom: 14px;
  }

  // Temporary overrides until new Typography designs are implemented
  && {
    h3 {
      font-size: 20px;
      font-weight: 700;
      color: #af021c;

      @media (min-width: ${tablet}px) {
        font-size: 28px;
      }
    }
  }
`;

export const RiskSectionContainer = styled.div<{ $stacked: boolean }>`
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  @media (min-width: ${tablet}px) {
    flex-direction: ${({ $stacked }) => ($stacked ? "column" : "row")};
  }

  ${({ $stacked }) =>
    $stacked &&
    css`
      flex-direction: column;
    `}
`;

export const RiskSection = styled.div`
  width: 100%;
  border: 1px solid #dedee3;
  border-radius: 5px;
  padding: 7px;

  @media (min-width: ${tablet}px) {
    padding: 10px 23px;
  }
`;

export const RiskList = styled.ul`
  margin-top: 10px;
  padding-left: 20px;
  list-style-type: none;
  padding: 0;
`;

export const RiskListItemIncreased = styled.li`
  font-size: 14px;
  line-height: 24px;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  padding-left: 30px;
  margin-bottom: 10px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDBfMjM1MF81Nzc1KSI+CiAgICA8cGF0aCBkPSJNNiAxMEMzLjc5NSAxMCAyIDguMjA1IDIgNkMyIDMuNzk1IDMuNzk1IDIgNiAyQzguMjA1IDIgMTAgMy43OTUgMTAgNkMxMCA4LjIwNSA4LjIwNSAxMCA2IDEwWk02IDExQzguNzYgMTEgMTEgOC43NiAxMSA2QzExIDMuMjQgOC43NiAxIDYgMUMzLjI0IDEgMSAzLjI0IDEgNkMxIDguNzYgMy4yNCAxMSA2IDExWk01LjUgNlY4SDYuNVY2SDhMNiA0TDQgNkg1LjVaIiBmaWxsPSIjQUYwMjFDIi8+CiAgPC9nPgogIDxkZWZzPgogIDxjbGlwUGF0aCBpZD0iY2xpcDBfMjM1MF81Nzc1Ij4KICAgIDxyZWN0IHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgZmlsbD0id2hpdGUiLz4KICA8L2NsaXBQYXRoPgogIDwvZGVmcz4KPC9zdmc+Cg==");
`;

export const RiskListItemDecreased = styled.li`
  font-size: 14px;
  line-height: 24px;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  padding-left: 26px;
  margin-bottom: 10px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNNiAyQzMuNzk1IDIgMiAzLjc5NSAyIDZDMiA4LjIwNSAzLjc5NSAxMCA2IDEwQzguMjA1IDEwIDEwIDguMjA1IDEwIDZDMTAgMy43OTUgOC4yMDUgMiA2IDJaTTYgMUM4Ljc2IDEgMTEgMy4yNCAxMSA2QzExIDguNzYgOC43NiAxMSA2IDExQzMuMjQgMTEgMSA4Ljc2IDEgNkMxIDMuMjQgMy4yNCAxIDYgMVpNNS41IDZWNEg2LjVWNkg4TDYgOEw0IDZINS41WiIgZmlsbD0iIzQ3OEMzQiIvPgo8L3N2Zz4=");
`;

export const TooltipContainer = styled.span`
  vertical-align: middle;
`;

export const ThankYou = styled.div`
  margin-top: 8px;

  @media (min-width: ${tablet}px) {
    margin-top: 0;
    padding-bottom: 16px;
  }
`;
