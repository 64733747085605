import styled, { createGlobalStyle } from "styled-components";
import {
  HEADER_HEIGHT_MOBILE,
  HEADER_HEIGHT_TABLET,
  TABS_HEIGHT_MOBILE,
  TABS_HEIGHT_TABLET,
} from "styles/global-styles";
import { mobile } from "styles/breakpoints";

export const PageContainer = styled.div``;

// Required to prevent scrolling on the body when the tabs are shown
export const GlobalStyles = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const headerTabsMobileHeight = HEADER_HEIGHT_MOBILE + TABS_HEIGHT_MOBILE;

export const FullWidthContainer = styled.div<{ $showTabs: boolean }>`
  width: 100%;
  display: flex;
  padding: 0 16px;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: ${HEADER_HEIGHT_MOBILE}px;
  height: ${({ $showTabs }) =>
    $showTabs ? `calc(100vh - ${headerTabsMobileHeight}px)` : "auto"};
  margin-bottom: ${({ $showTabs }) => ($showTabs ? TABS_HEIGHT_MOBILE : 0)}px;

  @media (min-width: ${mobile}px) {
    margin: ${({ $showTabs }) =>
      $showTabs ? `${HEADER_HEIGHT_TABLET}px auto 0 auto` : "51px auto 0 auto"};
    height: ${({ $showTabs }) =>
      $showTabs ? `calc(100vh - ${HEADER_HEIGHT_TABLET}px)` : "auto"};
  }
`;

export const MainContainer = styled.main<{ $showTabs: boolean }>`
  display: flex;
  height: auto;
  max-width: 1200px;
  flex-direction: column;
`;

export const HeaderContainer = styled.header`
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  position: fixed;
  background-color: white;
`;

export const TabsContainer = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  padding: 3px 16px 2px 16px;
  background-color: white;
  height: ${TABS_HEIGHT_MOBILE}px;
  border-top: 1px solid rgba(106, 110, 135, 0.42);

  @media (min-width: ${mobile}px) {
    padding: 0;
    margin: 0 auto;
    max-width: 1200px;
    position: relative;
    border-top: none;
    height: ${TABS_HEIGHT_TABLET}px;

    svg {
      display: none;
    }
  }
`;

export const TabButtonContents = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  svg {
    margin-bottom: 8px;
  }
`;
