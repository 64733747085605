import React from "react";
// @ts-ignore package has no types
import HtmlParser from "react-html-parser";
import { Typography } from "storybook/components/typography";
import { Accordion } from "storybook/components/accordion";
import { Loading } from "components/loading";
import { useAdminContent } from "context/admin-content";
import { useRiskReport } from "context/risk-report";
import { RiskSummary } from "components/risk-summary";
import { ReportContainer, Content } from "../styles";
import {
  GlobalStyle,
  PageHeading,
  HeadingContainer,
  SectionNumber,
  SectionHeading,
  SectionContent,
} from "./styles";

export const PatientResultsDisplay2 = () => {
  const adminData = useAdminContent();
  const {
    error,
    reportHtml,
    reportData,
    refetchRiskReport,
    isLoading: reportLoading,
  } = useRiskReport();

  const isLoading = adminData?.isLoading || reportLoading;

  if (isLoading) {
    return (
      <Content>
        <Loading />
      </Content>
    );
  }

  const Report = () => (
    <ReportContainer role="article">
      {HtmlParser(reportHtml, {
        transform: (node: any) =>
          node.attribs?.id === "page2" ? null : undefined,
      })}
    </ReportContainer>
  );

  return (
    <>
      <GlobalStyle />
      <PageHeading>Patient Results Page</PageHeading>
      <HeadingContainer>
        <SectionHeading>
          <SectionNumber variant="h4" component="h2" color="governorBay">
            1
          </SectionNumber>
          <Typography theme="default" variant="body1" color="governorBay">
            Risk Results
          </Typography>
        </SectionHeading>
      </HeadingContainer>
      <SectionContent>
        <Typography theme="default" variant="h5" component="h2" color="black">
          Your personalized risk assessment
        </Typography>
        <RiskSummary
          error={error}
          report={reportData}
          refetchRiskReport={refetchRiskReport}
        />
        <Accordion
          theme="default"
          title="Report Preview"
          previewContent={<Report />}
        >
          <Report />
        </Accordion>
      </SectionContent>
    </>
  );
};
