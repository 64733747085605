import "fontsource-dosis";
import { createTheme, ThemeType } from "storybook/utils/theme";

const colors = {
  bubblegum: "#BF2A7F",
  orBlue: "#3D78B3",
  ciqBrand: "#6A6E87",
  ciqBrandDark: "#545375",
  ciqBrandLight: "#696892",
  tranquility: "#54808C",
  hematite: "#141414",
  graphite: "#8E8E90",
  lightGrey: "#F4F4F4",
  greyBright: "#EEEEEE",
  disabledGrey: "#9A9BAC",
  oldLavendar: "#6a6b80",
  grass: "#478C3B",
  orangeJuice: "#B9680F",
  cherry: "#AF021C",
  white: "#FFFFFF",
  black: "#000000",

  // Tabs Mobile
  activeTabMobileText: "#851d58",
  activeTabMobileBg: "rgba(133, 29, 88, 0.20)",

  // Tabs Tablet / Desktop
  activeTabTabletText: "#48495C",
  activeTabTabletBg: "#FFFFFF",
  activeTabTabletUnderline: "#48495C",
  inactiveTabTabletText: "rgba(0, 0, 0, 0.54)",
} as const;

interface FutureThemeType extends ThemeType {
  colors: typeof colors;
}

export const futureTheme = createTheme({
  colors,
  name: "future",
  typography: {
    fontFamily: '"Dosis", sans-serif',
  },
}) as FutureThemeType;
