import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
  }
  * {
    box-sizing: border-box;

    // We're using the default theme of Storybook components, which use the Dosis font.
    // However, the Patient Portal designs use the Raleway font, so overriding here.
    font-family: "Raleway", sans-serif !important;
  }
`;

export const HEADER_HEIGHT_MOBILE = 56;
export const HEADER_HEIGHT_TABLET = 98;

export const TABS_HEIGHT_MOBILE = 68;
export const TABS_HEIGHT_TABLET = 48;
